import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import nodeService from "./nodesService";
//check if user and token are defined
const nodes = [];
let edges = [];
let initialNodeTypes = {};


const initialState = {
    nodes: nodes,
    edges: edges,
    initialNodeTypes: initialNodeTypes,
    nodeUpdateError: false,
    nodeUpdateSucess: false,
    nodeUpdateLoading: false,
    nodeUpdateMessage: "",
};


//addNodes
export const addNodes = createAsyncThunk(
    "nodeState/addNode",
    async (node, thunkAPI) => {
        try {
            return await nodeService.addNode(node);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


//getNodes
export const getAllNodes = createAsyncThunk(
    "nodeState/getAllNodes",
    async (thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const nodes = state.nodeState.nodes;
            console.log(nodes);
            return await nodeService.getAllNodes(nodes);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);




//updateNodes
export const updateNodes = createAsyncThunk(
    "nodeState/updateNodes",
    async (nodes, thunkAPI) => {
        try {
            return await nodeService.updateNodes(nodes);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);


//setNodeTypesList
export const setNodeTypesList = createAsyncThunk(
    "nodeState/setNodeTypesList",
    async (nodes, thunkAPI) => {
        try {
            return await nodeService.setNodeTypesList(nodes);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);



export const nodeSlice = createSlice({
    name: "nodeState",
    initialState,
    reducers: {
        resetNode: (state) => {
            state.nodeUpdateError = false;
            state.nodeUpdateSucess = false;
            state.nodeUpdateLoading = false;
            state.nodeUpdateMessage = "";
            state.nodes = [];
            state.edges = [];
        },
        setBotData: (state, action) => {

            const newNodes = nodeService.setBotData(action.payload);
            state.nodes = newNodes;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(addNodes.pending, (state) => {
                state.nodeUpdateLoading = true;
            })
            .addCase(addNodes.fulfilled, (state, action) => {
                state.nodeUpdateLoading = false;
                state.nodeUpdateSucess = true;
                if (Array.isArray(action.payload)) {
                    const duplicates = [...state.nodes, ...action.payload];

                    const uniqueNodes = duplicates.reduce((acc, node) => {
                        acc[node.id] = node;
                        return acc;
                    }, {});

                    state.nodes = [...state.nodes, ...Object.values(uniqueNodes)];
                } else {
                    state.nodes = [...state.nodes, action.payload];
                }
            })
            .addCase(addNodes.rejected, (state, action) => {
                state.nodeUpdateLoading = false;
                state.nodeUpdateError = true;
                state.nodeUpdateMessage = action.payload;

            })
            .addCase(getAllNodes.pending, (state) => {
                state.nodeUpdateLoading = true;
            })
            .addCase(getAllNodes.fulfilled, (state, action) => {
                state.nodeUpdateLoading = false;
                state.nodeUpdateSucess = true;
                console.log(action.payload);
                state.nodeUpdateMessage = "Nodes get successfull!"
            })
            .addCase(getAllNodes.rejected, (state, action) => {
                state.nodeUpdateLoading = false;
                state.nodeUpdateError = true;
                state.nodeUpdateMessage = action.payload;

            })
            .addCase(updateNodes.pending, (state) => {
                state.nodeUpdateLoading = true;
            })
            .addCase(updateNodes.fulfilled, (state, action) => {
                state.nodeUpdateLoading = false;
                state.nodeUpdateSucess = true;
                console.log(action.payload);
                state.nodes = [...state.nodes, ...action.payload];
                state.nodeUpdateMessage = "Nodes update successfull!";
            })
            .addCase(updateNodes.rejected, (state, action) => {
                state.nodeUpdateLoading = false;
                state.nodeUpdateError = true;
                state.nodeUpdateMessage = action.payload;

            })
            .addCase(setNodeTypesList.pending, (state) => {
                state.nodeUpdateLoading = true;
            })
            .addCase(setNodeTypesList.fulfilled, (state, action) => {
                state.nodeUpdateLoading = false;
                state.nodeUpdateSucess = true;
                console.log(action.payload)
                state.initialNodeTypes = action.payload;
                state.nodeUpdateMessage = "Inital Nodes update successfull!";
            })
            .addCase(setNodeTypesList.rejected, (state, action) => {
                state.nodeUpdateLoading = false;
                state.nodeUpdateError = true;
                state.nodeUpdateMessage = action.payload;

            })

    },
});
export const { resetNode, setBotData } = nodeSlice.actions;
export default nodeSlice.reducer;
