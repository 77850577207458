import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";
//check if user and token are defined
let user = null;
let token = null;
let keys = [];

if (localStorage.getItem("user") != null) {
  user = { user: JSON.parse(localStorage.getItem("user")) };
} else {
  user = null;
}

if (localStorage.getItem("key") != null) {
  keys = JSON.parse(localStorage.getItem("keys"));
} else {
  keys = [];
}

if (localStorage.getItem("token") === undefined) {
  token = null;
} else {
  token = JSON.parse(localStorage.getItem("token"));
}

const initialState = {
  user: user,
  token: token,
  keys: keys,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  botMemory: [],
};

//register user

export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      return await authService.register(user);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update account details

export const updateAccountDetails = createAsyncThunk(
  "auth/updateAccountDetails",
  async (userDetails, thunkAPI) => {
    try {
      const response = await authService.updateAccountDetails({
        userDetails,
        token,
      });
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update user

export const updateUserPersonal = createAsyncThunk(
  "auth/updateUserPersonal",
  async (data, thunkAPI) => {
    try {
      console.log(data);
      //const user_token = thunkAPI.getState().auth.token;
      const response = await authService.updateUserPersonal({
        data,
        token,
      });
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get user

export const getUser = createAsyncThunk(
  "auth/getAllUsers",
  async (id, thunkAPI) => {
    try {
      //const user_token = thunkAPI.getState().auth.token;
      const response = await authService.getUser(id, token);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

//login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// //get usage
// export const get_usage = createAsyncThunk(
//   "auth/get_usage",
//   async (user, thunkAPI) => {
//     try {
//       return await authService.get_usage(user);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

//generate api
export const generateApi = createAsyncThunk(
  "auth/generateApi",
  async (data, thunkAPI) => {
    try {
      return await authService.generateApi(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete api
export const deleteApi = createAsyncThunk(
  "auth/deleteApi",
  async (key, thunkAPI) => {
    try {
      return await authService.deleteApi(key);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//generate verificaion token
export const generateVerificationToken = createAsyncThunk(
  "auth/generateVerificationToken",
  async (data, thunkAPI) => {
    try {
      return await authService.generateVerificationToken(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//delete verificaion token
export const deleteVerificationToken = createAsyncThunk(
  "auth/deleteVerificationToken",
  async (data, thunkAPI) => {
    try {
      return await authService.deleteVerificationToken(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//edit current user password
export const resetUserPassword = createAsyncThunk(
  "auth/resetUserPassword",
  async (data, thunkAPI) => {
    try {
      return await authService.resetUserPassword(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get current user memory of bot
export const getUserBotMemory = createAsyncThunk(
  "auth/getUserBotMemory",
  async (data, thunkAPI) => {
    try {
      return await authService.getUserBotMemory(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetUserComplete: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      localStorage.clear();
    },
    resetUser: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.token = action.payload.token;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(updateUserPersonal.pending, (state) => {
        //state.isLoading = true;
      })
      .addCase(updateUserPersonal.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.status;
        console.log(action.payload);
        state.user.user.first_name = action.payload.userDetails.first_name;
        state.user.user.last_name = action.payload.userDetails.last_name;
      })
      .addCase(updateUserPersonal.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateAccountDetails.pending, (state) => {
        //state.isLoading = true;
      })
      .addCase(updateAccountDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(updateAccountDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
        state.token = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.user = null;
      })
      // .addCase(get_usage.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(get_usage.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.usage = action.payload;
      // })
      // .addCase(get_usage.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.message = action.payload;
      // })
      .addCase(generateApi.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(generateApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.keys = [...state.keys, action.payload];
        localStorage.setItem("keys", JSON.stringify(state.keys));
      })
      .addCase(generateApi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteApi.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteApi.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
      })
      .addCase(deleteApi.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(generateVerificationToken.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(generateVerificationToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.user.user.verification_token = action.payload;
      })
      .addCase(generateVerificationToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(deleteVerificationToken.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteVerificationToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.user.user.verification_token = "";
      })
      .addCase(deleteVerificationToken.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(resetUserPassword.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
      })
      .addCase(getUserBotMemory.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserBotMemory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.message = action.payload;

        console.log("getUserBotMemory", action.payload);

        const bot_id = action.meta.arg.bot_id;
        const memoryData = action.payload;
        const existingBotIndex = state.botMemory.findIndex(
          (memory) => memory.bot_id === bot_id
        );
        if (existingBotIndex !== -1) {
          // If bot_id exists, update the memory data for that bot
          state.botMemory[existingBotIndex] = {
            ...state.botMemory[existingBotIndex],
            generation_memory: memoryData.generation_memory,
            retrieval_memory: memoryData.retrieval_memory,
          };
        } else {
          // If bot_id doesn't exist, add a new entry to the array
          state.botMemory.push({
            bot_id: bot_id,
            generation_memory: memoryData.generation_memory,
            retrieval_memory: memoryData.retrieval_memory,
          });
        }
      })
      .addCase(getUserBotMemory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.error.message;
      });
  },
});
export const { resetUserComplete, resetUser } = authSlice.actions;
export default authSlice.reducer;
