import _ from "lodash";
import { isEqual } from "../../UtilityFunctions";

//addNode
const addNode = (node) => {
    return node;
};

//getAllNodes
const getAllNodes = (nodes) => {
    return nodes;
};

const updateNodes = (nodes) => {
    if (!nodes || typeof nodes !== 'object') {
        throw new TypeError('Input must be an object containing oldNodes and newNodes');
    }

    const { oldNodes, newNodes } = nodes;

    if (!Array.isArray(oldNodes) || !Array.isArray(newNodes)) {
        throw new TypeError('oldNodes and newNodes must be arrays');
    }

    if (oldNodes === newNodes) {
        return newNodes;
    }

    const oldNodeIds = new Set(oldNodes.map(node => node.id));
    const updatedNodes = oldNodeIds.size > 0 ? newNodes.map(node => ({ ...node })) : newNodes;

    if (oldNodeIds.size > 0) {
        const oldNodesMap = new Map(oldNodes.map(node => [node.id, node]));

        for (let i = 0; i < updatedNodes.length; i++) {
            const newNode = updatedNodes[i];
            if (!newNode || typeof newNode !== 'object' || !newNode.id) {
                throw new TypeError(`Invalid node at index ${i}`);
            }

            if (oldNodeIds.has(newNode.id)) {
                const oldNode = oldNodesMap.get(newNode.id);
                if (oldNode && oldNode.data && newNode.data) {
                    if (!isEqual(newNode.data.data_, oldNode.data.data_)) {
                        newNode.data = { ...newNode.data, ...oldNode.data };
                    }
                }
            }
        }
    }

    return updatedNodes;
};

//setBotData
const setBotData = (data) => {
    const { id, type, value, nodes } = data;
    const _nodes_ = nodes.map(node => ({ ...node, data: { ...node.data } }));
    const index = _nodes_.findIndex((node) => id === node.id);
    if (index === -1) {
        throw new TypeError(`Node with id ${id} not found!`);
    }

    if (_nodes_[index] && _nodes_[index].data && _nodes_[index].data.botData) {
        _nodes_[index].data.botData = {
            ..._nodes_[index].data.botData,
            [type]: value
        };

        const uniqueNodes = _nodes_.reduce((acc, nd) => {
            acc[nd.id] = nd;
            return acc;
        }, {});

        return Object.values(uniqueNodes);
    } else {
        throw new TypeError(`${id} not found or missing required properties for saving ${type}`);
    }
}


const setNodeTypesList = (data) => {
    console.log("Inside setNodeTypesList: ", data);
    if (!data && typeof data !== 'object') {
        throw new TypeError("Node types must be an object!");
    }

    if (data.length === 0) {
        throw new TypeError("Node types should not be empty!");
    }

    return { ...data };
}


const nodeService = {
    addNode,
    getAllNodes,
    updateNodes,
    setBotData,
    setNodeTypesList
}

export default nodeService;